<template>
  <div class="lg:px-6 max-w-md mx-auto h-screen shadow-lg" id="container">
      <HeaderComponent/>
    <div class="border-gray-300 border-t p-8">
      <router-link to="/" class="m-0 ">
          <svg aria-hidden="true" class="h-8 w-8 text-white" fill="none" stroke="currentColor" stroke-width="1.5"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </router-link>
      <div class="flex flex-col justify-start px-8">
        <p class="text-white font-bold w-full text-2xl text-center underline">
          WIN-UP
        </p>
        <p class="text-white w-full text-center" style="font-size: 9px">{{ $t('home.title') }} !</p>
      </div>
    </div>

    <LoadingComponent v-if="isLoading" />

    <div
      class="flex bg-yellow-100 rounded-lg p-4 mx-4 text-sm text-yellow-700"
      role="alert"
      v-else-if="prognosticNone"
    >
      <svg
        class="w-8 h-8 inline mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div class="text-center">Aucun pronostique disponible</div>
    </div>

    <div class="grid w-full px-8 gap-4" v-else>
      <div
        v-for="prognostic in this.prognostics"
        v-bind:key="prognostic.id"
        class="bg-white bg-opacity-70 rounded hover:cursor-pointer"
        @click="handleClickPrognostic(prognostic.id)"
      >
        <div class="flex items-center w-full justify-between py-4 px-4 gap-2">
          <div class="text-center">
            <img
              :src="prognostic.team1_flag"
              class="h-6"
              :alt="prognostic.team1_code"
            />
            <span class="font-bold" style="color: #005094">{{ prognostic.team1_code }}</span>
          </div>
          <span class="text-red-600 text-3xl">VS</span>
          <div class="text-center">
            <img
              :src="prognostic.team2_flag"
              class="h-6"
              :alt="prognostic.team2_code"
            />
            <span class="font-bold" style="color: #005094">{{ prognostic.team2_code }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center fixed bottom-4 left-0 right-0">
      <a
        href="https://nexah.net"
        style="font-size: 11px"
        class="text-white underline"
        >{{ $t("home.nexah") }}</a
      >
    </div>
  </div>
</template>
<script>
import logo from "@/assets/img/cmca-logo.png";
import om from "@/assets/img/om.png";
import momo from "@/assets/img/momo.jpeg";
import "../assets/radio.css";
import "../assets/loader.css";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "AllPrognostics",
  components: {
    HeaderComponent,
    LoadingComponent,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      logo,
      om,
      momo,
      img: "",
      prognostics: [],
      form: {
        firstAnswer: null,
        secondAnswer: null,
        phone: null,
      },
      interval: null,
      isLoading: false,
      prognosticNone: false,
    };
  },
  mounted() {
    this.isLoading=true;
    axios
      .get("https://ms-win-up-preprod.nexah.net/api/v1/prognostic-display")
      .then((response) => {
        this.prognosticNone = false;
        this.prognostics = response.data.data.prognostics;
        this.isLoading = false;
      })
      .catch(() => {
        console.log("Il y a pas de pronostic pour le moment");
      });
  },
  methods: {
    handleClickPrognostic(id) {
      this.$router.push(`/prognostic/${btoa(id)}`);
    },
  },
};
</script>

<style>
#container {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../assets/img/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
