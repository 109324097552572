
import { createRouter, createWebHistory } from 'vue-router'
import WinUp from '@/view/WinUp.vue'
import Home from '@/view/Home'
import PrognosticDetail from '@/view/PrognosticDetail.vue'
import Prognostic from '@/view/Prognostic.vue'

const router = new createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: WinUp
        },
        {
            path: '/question/:questionId?',
            component: Home
        },
        {
            path: '/prognostic',
            component: Prognostic
        },
        {
            path: '/prognostic/:id',
            component: PrognosticDetail
        },
    ]
})

export default router