<template>
  <div class="lg:px-6 max-w-md mx-auto h-screen shadow-lg" id="container">
    <router-link to="/">
      <HeaderComponent/>
    </router-link>
    <div class="border-gray-300 border-t p-8">
        <router-link to="/" class="m-0 ">
          <svg aria-hidden="true" class="h-8 w-8 text-white" fill="none" stroke="currentColor" stroke-width="1.5"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </router-link>
      <div class="flex flex-col justify-start px-8">
        <p class="text-white font-bold w-full text-2xl text-center underline">
          WIN-UP
        </p>
        <p class="text-white w-full text-center" style="font-size: 9px">{{ $t('home.title') }} !</p>
      </div>
    </div>

    <LoadingComponent v-if="isLoading"/>

    <div class="flex bg-yellow-100 rounded-lg p-4 mx-4 text-sm text-yellow-700" role="alert" v-else-if="questionNone">
      <svg class="w-8 h-8 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
      </svg>
      <div class="text-center">
        {{ $t('home.noQuestion') }}
      </div>
    </div>

    <div v-else-if="!status">
      <div class="flex flex-col justify-center items-center w-full mx-auto px-16" v-if="!play">
        <img class="rounded-3xl" :src="question.img" alt="Gain à gagné"/>
        <button @click="handleClickNext" class="text-center flex justify-center items-center rounded-2xl text-white bg-green-700 w-32 p-2 mt-4">
          <span>{{ $t('home.play') }}</span>
          <svg aria-hidden="true" class="h-4 w-4 ml-2" fill="none" stroke="currentColor" stroke-width="6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
      </div>
      <form @submit.prevent="submitForm" v-else>
        <div class="flex flex-col bg-black border-gray-300 border bg-opacity-40 rounded-2xl mx-12 px-4 py-4 mb-6">
          <p class="text-white text-center mb-4">{{ question.title }}</p>
          <div class="border-t border-gray-300"></div>
          <div>
            <div v-for="(choice, index) in choices" :key="index">
              <div class="flex items-center text-white py-2 gap-2 mt-1 justify-between">
                <label :for="'answer' + choice.id">
                  {{ letters[index] }}- {{ choice.answer }}
                </label>
                <input v-model="form.choiceId" name="answer" :value="choice.id" :id="'answer' + choice.id" type="radio"
                       class="form-radio float-right" v-if="!isCheck"/>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <input id="phone" :placeholder="$t('home.phone')" type="number" v-model="form.phone"
                 class="rounded-2xl p-2 text-center bg-white w-64"/><br>
          <button @click="handleClickVote" type="submit" class="rounded-2xl text-white bg-green-700 w-64 p-2 mt-2">
            {{ $t('home.vote') }} ({{question.amount}} XAF)
          </button>
        </div>

        <div class="flex justify-center items-center w-full mx-auto py-5 ">
            <img :src="om" alt="Om" class="h-12"/>
            <img :src="momo" alt="Momo" class="h-12 mx-2"/>
        </div>
      </form>
    </div>

    <CheckingComponent v-if="status === 'PENDING'"/>
    <FailedComponent v-if="status === 'FAILED'"/>
    <SuccessComponent v-if="status === 'SUCCESS'"/>

    <div class="flex justify-center fixed bottom-4 left-0 right-0">
      <a href="https://nexah.net" style="font-size: 11px" class="text-white underline">{{ $t('home.nexah') }}</a>
    </div>
  </div>

</template>
<script>

import logo from "@/assets/img/cmca-logo.png";
import om from "@/assets/img/om.png";
import momo from "@/assets/img/momo.jpeg";
import '../assets/radio.css'
import '../assets/loader.css'
import CheckingComponent from "@/components/CheckingComponent.vue";
import FailedComponent from "@/components/FailedComponent.vue";
import SuccessComponent from "@/components/SuccessComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";
import {toast} from "vue3-toastify";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: 'QuestionPage',
  components: {
    HeaderComponent,
    FailedComponent,
    CheckingComponent,
    SuccessComponent,
    LoadingComponent,
  },
  props: {
    msg: String
  },
  data() {
    return {
      logo,
      om,
      momo,
      img: "",
      selectedChoiceId: null,
      question: [],
      choices: [],
      letters: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'x', 'y', 'z'],
      form: {
        phone: null,
        choiceId: null,
      },
      interval: null,
      status: null,
      isLoading: false,
      isExpired: false,
      isAlert: false,
      isCheck: false,
      next: false,
      play: false,
      questionNone: false,
    }
  },
  methods: {
    handleClickVote() {
      console.log(this.form)
      if (this.form.phone !== null && this.form.choiceId !== null) {
        this.status = 'PENDING';
        axios.post(`https://ms-win-up-preprod.nexah.net/api/v1/vote`, {
          ...this.form,
          questionId: this.question.id
        }).then((response) => {
          this.checkPayment(response.data.payment_ref);
        }).catch(() => {
          console.log("erreur");
        })
      } else {
        toast.warn(this.$i18n.t("home.instruction"), {
          position: toast.POSITION.TOP_CENTER,
          toastClassName: 'warning',
        });
      }
    },

    handleClickNext() {
      this.next = true
      this.play = true;
    },

    checkPayment(payment_ref) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        axios.post(`https://ms-win-up-preprod.nexah.net/api/v1/pay-status/` + payment_ref).then((response) => {
          if (response.data.status === 'SUCCESS') {
            this.status = 'SUCCESS';
          } else if (response.data.status === 'FAILED' || response.data.status === 'CANCELLED' || response.data.status === 'EXPIRED') {
            this.status = 'FAILED';
          }
        })
      }, 5000);
    },
  },

  mounted() {
    this.isLoading = true;
    let url = this.$route.params.questionId ? 'https://ms-win-up-preprod.nexah.net/api/v1/display' : 'https://ms-win-up-preprod.nexah.net/api/v1/display';
    axios.get(url).then((response) => {
      if (response.data.data.is_expired === 1) {
        this.isCheck = true;
        this.isExpired = true;
        this.question = response.data.data.question;
        this.choices = response.data.data.choices;
        this.img = response.data.data.question.img;
        this.isLoading = false;
      } else if (response.data.data.is_expired === 2) {
        this.isLoading = false
        this.questionNone = true
      } else {
        this.questionNone = false
        this.question = response.data.data.question;
        this.choices = response.data.data.choices;
        this.img = response.data.data.question.img
        this.isLoading = false;
      }
    }).catch(() => {
      console.log("Il y a pas de question pour le moment");
    });
  }
}
</script>


<style>
#container {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../assets/img/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.warning{
  --toastify-color-progress-warning: var(--toastify-color-error);
  --toastify-icon-color-warning: var(--toastify-color-error);
}
</style>

