<template>
  <select v-model="this.$i18n.locale"
    class="w-16 px-3 py-1 text-gray-700 bg-white rounded text-sm">
    <option selected value="fr">{{ $t('home.lang-fr') }}</option>
    <option value="en">{{ $t('home.lang-en') }}</option>
  </select>
</template>

<script>
export default {
  name: "LangComponent"
}
</script>

<style scoped></style>