import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import {createI18n} from "vue-i18n";


const messages ={
    en:{
        home: {
            'title':'Try your luck and win',
            'instruction':'To play, you might make a choice and enter your phone number',
            'instruction_prognostic':'To play, you might give a score and enter your phone number',
            'noQuestion':'No question available for the moment !',
            'nexah':'Provided by NEXAH',
            'vote':'Vote',
            'phone':'Phone number',
            'play':'Play',
            'lang-en':'En',
            'lang-fr':'Fr',
            'other':'Other events',
        },
        checking: {
            'title':'Checking',
            'message':'Your vote is being verified ...',
            'warning':'If you do not receive an instant notification, please click on your operator\'s image.',
        },
        failed: {
            'title':'Offside',
            'message':'Your answer validation was not successful. Please check your balance and try again.',
        },
        goal: {
            'title':'Goal',
            'message':'Congratulations! Your vote was successful !',
        },
        winup: {
            'buttonPrognostic':'Prognostics',
            'buttonQuestion':'Quiz',
        }

    },
    fr:{
        home: {
            'title':'Tente ta chance et gagne',
            'instruction':'Pour jouer vous devez faire un choix, et saisir votre numéro de téléphone',
            'instruction_prognostic':'Pour jouer vous devez donner un score, et saisir votre numéro de téléphone',
            'noQuestion':'Aucune question disponible pour le moment !',
            'nexah':'Fourni par NEXAH',
            'vote':'Voter',
            'phone':'Numéro de téléphone',
            'play':'Jouer',
            'lang-fr':'Fr',
            'lang-en':'En',
            'other':'Autres rencontres'
        },
        checking: {
            'title':'Vérification',
            'message':'Votre vote est en cours de vérification ...',
            'warning':'Si vous ne recevez pas de notification instantanée, veuillez cliquer sur l\'image de votre opérateur.',
        },
        failed: {
            'title':'Hors-jeu',
            'message':'La validation de votre réponse n\'a pas abouti. Veuillez vérifier votre solde et Réessayer.',
        },
        goal: {
            'title':'But !',
            'message':'Votre vote a été effectué avec succès !',
        },
        winup: {
            'buttonPrognostic':'Pronostics',
            'buttonQuestion':'Quiz',
        }
    }
}

const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages,
})

const app = createApp(App)

app.use(i18n)
app.use(router)
app.mount('#app')
