<template>
  <div class="flex justify-between px-6 w-full">
    <router-link to="/">
      <img class="w-32 py-3" :src="logo" alt="CMCA"/>
    </router-link>
    <div class="flex items-center">
      <LangComponent/>
    </div>
  </div>
</template>

<script>

import logo from "@/assets/img/cmca-logo.png";
import LangComponent from "@/components/LangComponent.vue";

export default {
  name: "HeaderComponent",
  components: {LangComponent},

  data() {
    return {
      logo
    }
  }
}
</script>