<template>
  <div class="lg:px-6 max-w-md mx-auto h-screen shadow-lg" id="container">
    <HeaderComponent/>
    <div class="border-gray-300 border-t p-8">
      <div v-if="!play">
        <router-link to="/prognostic" class="m-0">
          <svg aria-hidden="true" class="h-8 w-8 text-white" fill="none" stroke="currentColor" stroke-width="1.5"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </router-link>
      </div>
      <div class="flex flex-col justify-start px-8">
        <p class="text-white font-bold w-full text-2xl text-center underline">
          WIN-UP
        </p>
        <p class="text-white w-full text-center" style="font-size: 9px">{{ $t('home.title') }} !</p>
      </div>
    </div>

    <LoadingComponent v-if="isLoading"/>
    <div class="flex bg-yellow-100 rounded-lg p-4 mx-4 text-sm text-yellow-700" role="alert" v-else-if="prognosticNone">
      <svg class="w-8 h-8 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
      </svg>
      <div class="text-center">
        Aucun pronostique disponible
      </div>
    </div>

    <!--      <div class="/flex flex-col justify-center items-center w-full mx-auto px-16" v-if="!play">-->
    <!--        <img class="rounded-3xl" :src="prognostic.img" alt="Gain à gagné"/>-->
    <!--        <button @click="handleClickNext"-->
    <!--                class="text-center flex justify-center items-center rounded-2xl text-white bg-green-700 w-32 p-2 mt-4">-->
    <!--          <span>{{ $t('home.play') }}</span>-->
    <!--          <svg aria-hidden="true" class="h-4 w-4 ml-2" fill="none" stroke="currentColor" stroke-width="6"-->
    <!--               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
    <!--            <path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>-->
    <!--          </svg>-->
    <!--        </button>-->
    <!--      </div>-->

    <div v-else-if="!status">
      <form @submit.prevent="submitForm">
        <div class="flex flex-col bg-black border-gray-300 border bg-opacity-40 rounded-2xl mx-12 px-4 py-4 mb-6">
          <p class="text-white text-center mb-4">
            {{ $i18n.locale === 'fr' ? this.prognostic.title : this.prognostic.title_en }}</p>
          <div class="border-t border-gray-300"></div>
          <div>
            <div class="py-2 mt-1">
              <div class="flex justify-between gap-2">
                <div class="flex items-center">
                  <img class="h-6 mx-2" :src="prognostic.team1_flag" alt=""/>
                  <label class="text-white" for="firstAnswer">
                    {{ $i18n.locale === 'fr' ? this.prognostic.team1_name_fr : this.prognostic.team1_name_en }}
                  </label>
                </div>
                <input v-model="form.firstAnswer" name="firstAnswer" id="firstAnswer" type="text"
                       class="border-b-blue-400 rounded p-2 text-center h-10 w-10" maxlength="2"/>
              </div>
              <div class="flex justify-between gap-2 mt-2">
                <div class="flex items-center">
                  <img class="h-6 mx-2" :src="prognostic.team2_flag" alt=""/>
                  <label class="text-white" for="firstAnswer">
                    {{ $i18n.locale === 'fr' ? this.prognostic.team2_name_fr : this.prognostic.team2_name_en }}
                  </label>
                </div>
                <input v-model="form.secondAnswer" name="secondAnswer" id="secondAnswer"
                       class="border-b-blue-400 rounded p-2 text-center h-10 w-10" maxlength="2"/>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <input id="phone" :placeholder="$t('home.phone')" type="number" v-model="form.phone"
                 class="rounded-2xl p-2 text-center bg-white w-64" minlength="9" maxlength="9"/><br>
          <button @click="handleClickVote" type="submit" class="rounded-2xl text-white bg-green-700 w-64 p-2 mt-2">
            {{ $t('home.vote') }} ({{ prognostic.amount }} XAF)
          </button>
        </div>

        <div class="flex justify-center items-center w-full mx-auto py-5 ">
          <img :src="om" alt="Om" class="h-12"/>
          <img :src="momo" alt="Momo" class="h-12 mx-2"/>
        </div>
      </form>

      <div class="mt-8 p-3 border-t border-white " v-if="!isLoading">
        <h4 class="text-white mb-4">{{ $t('home.other') }}</h4>
        <swiper :slides-per-view="1.15" :space-between="15" @swiper="onSwiper" @slideChange="onSlideChange">
          <swiper-slide class="cursor-pointer" type="submit" v-for="prognostic in prognostics" v-bind:key="prognostic.id"
                        @click="handleClickPrognostic(prognostic)">
            <div class="flex justify-between items-center bg-opacity-70 bg-gray-100 py-2 px-4 rounded text-white h-16 gap-3"
                style="color: #00569c;">
              <img class="h-6" :src="prognostic.team1_flag" alt=""/>
              <div class="flex justify-center items-center gap-2">
                <p class="text-center font-bold">{{ prognostic.team1_code }}</p>
                <span class="text-red-600 font-bold text-3xl">VS</span>
                <p class="text-center font-bold">{{ prognostic.team2_code }}</p>
              </div>
              <img class="h-6" :src="prognostic.team2_flag" alt=""/>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <CheckingComponent v-if="status === 'PENDING'"/>
    <FailedComponent v-if="status === 'FAILED'"/>
    <SuccessComponent v-if="status === 'SUCCESS'"/>
  </div>

</template>
<script>

import om from "@/assets/img/om.png";
import momo from "@/assets/img/momo.jpeg";
import '../assets/radio.css'
import '../assets/loader.css'
import CheckingComponent from "@/components/CheckingComponent.vue";
import FailedComponent from "@/components/FailedComponent.vue";
import SuccessComponent from "@/components/SuccessComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import HeaderComponent from "@/components/HeaderComponent.vue";
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'PrognosticDetails',
  components: {
    HeaderComponent,
    FailedComponent,
    CheckingComponent,
    SuccessComponent,
    LoadingComponent,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },

  props: {
    msg: String
  },
  data() {
    return {
      om,
      momo,
      img: "",
      selectedChoiceId: null,
      prognostic: [],
      dataPrognostics: [],
      prognostics: [],
      form: {
        firstAnswer: null,
        secondAnswer: null,
        phone: null,
      },
      interval: null,
      status: null,
      isLoading: false,
      isExpired: false,
      isAlert: false,
      next: false,
      play: false,
      prognosticNone: false,
      lang: this.$i18n.locale,
      title: null,
      title_en: null
    }
  },
  mounted() {
    this.isLoading = true;
    axios.get('https://ms-win-up-preprod.nexah.net/api/v1/prognostic/' + this.$route.params.id).then((response) => {
      //this.isExpired = true;
      this.prognosticNone = false
      this.prognostic = response.data.data;
      this.img = response.data.data.img
      this.isLoading = false;
    }).catch(() => {
      console.log("Il y a pas de pronostic pour le moment");
    });

    axios.get("https://ms-win-up-preprod.nexah.net/api/v1/prognostic-display").then((response) => {
      this.prognosticNone = false;
      this.dataPrognostics = response.data.data.prognostics;
      this.prognostics = this.dataPrognostics.filter((prognostic) => prognostic.id !== parseInt(atob(this.$route.params.id)));
      this.isLoading = false;
    }).catch(() => {
      console.log("Il y a pas de pronostic pour le moment");
    });
  },


  methods: {
    handleClickPrognostic(pronostic) {
      this.prognostic = pronostic;
      this.prognostics = this.dataPrognostics.filter((prognostic) => prognostic.id !== pronostic.id)
    },
    handleClickVote() {
      console.log(this.form.phone)
      console.log(this.form.firstAnswer)
      console.log(this.form.secondAnswer)
      if (this.form.phone !== null && this.form.firstAnswer !== null && this.form.secondAnswer !== null) {
        this.status = 'PENDING';
        axios.post(`https://ms-win-up-preprod.nexah.net/api/v1/vote`, {
          ...this.form, prognosticId: this.prognostic.id
        }).then((response) => {
          this.checkPayment(response.data.payment_ref);
        }).catch(() => {
          console.log("erreur");
        })
      } else {
        toast.warn(this.$i18n.t("home.instruction_prognostic"), {
          position: toast.POSITION.TOP_CENTER,
          toastClassName: 'warning',
        });
      }
    },
    checkPayment(payment_ref) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        axios.post(`https://ms-win-up-preprod.nexah.net/api/v1/pay-prono-status/` + payment_ref).then((response) => {
          if (response.data.status === 'SUCCESS') {
            this.status = 'SUCCESS';
          } else if (response.data.status === 'FAILED' || response.data.status === 'CANCELLED' || response.data.status === 'EXPIRED') {
            this.status = 'FAILED';
          }
        })
      }, 5000);
    },
  }
}

</script>


<style>
#container {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../assets/img/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.warning {
  --toastify-color-progress-warning: var(--toastify-color-error);
  --toastify-icon-color-warning: var(--toastify-color-error);
}
</style>

