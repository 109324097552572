<template>
  <router-view></router-view>
</template>

<script>
import "@fontsource/poppins";

export default {
  name: "App",
};
</script>

<style>
#app {
  background-color: #00569c;
}
</style>
