<template>
  <div class="lg:px-6 max-w-md mx-auto h-screen shadow-lg" id="container">
    <HeaderComponent/>
    <div class="border-gray-300 border-t py-8">
      <p class="text-white font-bold w-full text-2xl text-center underline">
        WIN-UP
      </p>
      <p class="text-white w-full text-center" style="font-size: 9px">{{ $t('home.title') }} !</p>
    </div>

    <div class="flex flex-col justify-between items-center text-white">
      <img class="rounded w-full px-8" :src="image" alt="CMCA"/>
      <router-link to="/prognostic" class="grid grid-cols-2 w-1/2 bg-green-700 text-center p-3 mt-4 rounded-xl">
        {{ $t('winup.buttonPrognostic') }}
        <div class="flex items-center justify-end">
          <svg aria-hidden="true" class="h-4 w-4" fill="none" stroke="currentColor" stroke-width="2.5"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </div>
      </router-link>
      <router-link to="/question" class="grid grid-cols-2 w-1/2 rounded-xl text-center px-4 bg-blue-700 py-3 mt-2">
        {{ $t('winup.buttonQuestion') }}
        <div class="flex items-center justify-end">
          <svg aria-hidden="true" class="h-4 w-4" fill="none" stroke="currentColor" stroke-width="2.5"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </div>
      </router-link>
    </div>

<!--    <div class="flex justify-center fixed bottom-4 left-0 right-0">-->
<!--      <a href="https://nexah.net" style="font-size: 11px" class="text-white underline">{{ $t('home.nexah') }}</a>-->
<!--    </div>-->
  </div>

</template>
<script>

import logo from "@/assets/img/cmca-logo.png";
import image from "@/assets/img/win-up-2.png";
import '../assets/radio.css'
import '../assets/loader.css'
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: 'WinUp',
  components: {HeaderComponent},
  props: {
    msg: String
  },
  data() {
    return {
      logo,
      image,
    }
  }

}
</script>


<style>
#container {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../assets/img/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>

